import React from "react";

type Props = {};

const about = (props: Props) => {
  // animate down onto page OR push page to the right on entry w FM
  // w staggered drop down
  return <div>about</div>;
};

export default about;
